@font-face {
font-family: '__openDyslexic_3f0ab8';
src: url(/_next/static/media/05e52f73849ffb70-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__openDyslexic_3f0ab8';
src: url(/_next/static/media/5f9a5cb1a4701ffb-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__openDyslexic_3f0ab8';
src: url(/_next/static/media/531711871523fcc4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}@font-face {font-family: '__openDyslexic_Fallback_3f0ab8';src: local("Arial");ascent-override: 74.92%;descent-override: 29.97%;line-gap-override: 0.00%;size-adjust: 173.53%
}.__className_3f0ab8 {font-family: '__openDyslexic_3f0ab8', '__openDyslexic_Fallback_3f0ab8'
}

